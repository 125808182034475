import React, { useState, useEffect } from 'react';
import { fetchCategories, analyzeAndSaveMessage, createMessage, createCategory, updateCategory, deleteCategory, updateMessage, deleteMessage, fetchMessages } from './apiService';
import './App.css';
import { Button, TextField, Select, MenuItem, CircularProgress, Typography, Container, Grid, Box, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Add, Edit, Delete, Mic } from '@mui/icons-material';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

function App() {
  const [messageContent, setMessageContent] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [userId] = useState(1); // Hardcoded for now
  const [loading, setLoading] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [messages, setMessages] = useState([]);
  const [editingMessage, setEditingMessage] = useState(null);
  const [newMessageContent, setNewMessageContent] = useState('');
  const [language, setLanguage] = useState('en-US');

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories();
        setCategories(data);
      } catch (error) {
        console.error('Failed to load categories:', error);
      }
    };

    const loadMessages = async () => {
      try {
        const data = await fetchMessages(userId);
        setMessages(data);
      } catch (error) {
        console.error('Failed to load messages:', error);
      }
    };

    loadCategories();
    loadMessages();
  }, [userId]);

  const handleAnalyzeAndSave = async () => {
    setLoading(true);
    try {
      const response = await analyzeAndSaveMessage(messageContent, userId);
      console.log('Message analyzed and saved:', response);
      setMessages([...messages, response]);
      alert('Message analyzed and saved successfully!');
    } catch (error) {
      console.error('Error analyzing and saving message:', error);
      alert('Error analyzing and saving message.');
    } finally {
      setLoading(false);
    }
  };

  const handleManualSave = async () => {
    if (!selectedCategoryId) {
      alert('Please select a category.');
      return;
    }

    setLoading(true);
    try {
      const response = await createMessage(messageContent, selectedCategoryId);
      console.log('Message created:', response);
      setMessages([...messages, response]);
      alert('Message saved successfully!');
    } catch (error) {
      console.error('Error saving message:', error);
      alert('Error saving message.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCategory = async () => {
    if (!editingCategory) return;

    setLoading(true);
    try {
      const response = await updateCategory(editingCategory.id, newCategoryName);
      console.log('Category updated:', response);
      setCategories(categories.map(category => category.id === editingCategory.id ? response : category));
      alert('Category updated successfully!');
      setEditingCategory(null);
      setNewCategoryName('');
    } catch (error) {
      console.error('Error updating category:', error);
      alert('Error updating category.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCategory = async (id) => {
    setLoading(true);
    try {
      await deleteCategory(id);
      setCategories(categories.filter(category => category.id !== id));
      alert('Category deleted successfully!');
    } catch (error) {
      console.error('Error deleting category:', error);
      alert('Error deleting category.');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenEditDialog = (category) => {
    setEditingCategory(category);
    setNewCategoryName(category.name);
  };

  const handleAddCategory = async () => {
    if (!newCategoryName) {
      alert('Please enter a category name.');
      return;
    }

    setLoading(true);
    try {
      // Pass newCategoryName and userId as separate parameters
      const response = await createCategory(newCategoryName, userId);
      setCategories([...categories, response]);
      setNewCategoryName('');
      alert('Category added successfully!');
    } catch (error) {
      console.error('Error adding category:', error);
      alert('Error adding category.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditMessage = (message) => {
    setEditingMessage(message);
    setNewMessageContent(message.content);
  };

  const handleUpdateMessage = async () => {
    if (!editingMessage) return;

    setLoading(true);
    try {
      const updatedMessage = {
        id: editingMessage.id,
        content: newMessageContent,
        categoryId: editingMessage.categoryId,
        createdAt: editingMessage.createdAt,
        updatedAt: new Date().toISOString(),
        tags: editingMessage.tags || [],
      };
      const response = await updateMessage(editingMessage.id, updatedMessage);
      console.log('Message updated:', response);
      setMessages(messages.map(msg => msg.id === editingMessage.id ? response : msg));
      alert('Message updated successfully!');
      setEditingMessage(null);
      setNewMessageContent('');
    } catch (error) {
      console.error('Error updating message:', error);
      alert('Error updating message.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteMessage = async (id) => {
    setLoading(true);
    try {
      await deleteMessage(id);
      setMessages(messages.filter(message => message.id !== id));
      alert('Message deleted successfully!');
    } catch (error) {
      console.error('Error deleting message:', error);
      alert('Error deleting message.');
    } finally {
      setLoading(false);
    }
  };

  const handleSpeechToText = async () => {
    const speechConfig = SpeechSDK.SpeechConfig.fromSubscription('iq7Yd1tjrujIbr6S9Af6GjCK1l9LoHyvO4Q5TEbuwGTNwLxA1t9iJQQJ99AKAC5T7U2XJ3w3AAAYACOGvb2j', 'francecentral');
    speechConfig.speechRecognitionLanguage = language;

    const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);

    recognizer.recognizeOnceAsync(result => {
      if (result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
        setMessageContent((prevContent) => prevContent + ' ' + result.text);
      } else {
        console.error('Speech recognition failed:', result.errorDetails);
      }
      recognizer.close();
    });
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Submit a Message
        </Typography>
        <TextField
          label="Enter your message"
          multiline
          fullWidth
          rows={4}
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
          variant="outlined"
          margin="normal"
        />
        <Box sx={{ my: 2 }}>
          {loading && <CircularProgress />}
          <Button
            variant="contained"
            color="primary"
            onClick={handleAnalyzeAndSave}
            disabled={loading}
            startIcon={<Add />}
            sx={{ mr: 2 }}
          >
            {loading ? 'Saving...' : 'Analyze and Save with AI'}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSpeechToText}
            startIcon={<Mic />}
            sx={{ ml: 2 }}
          >
            Speak
          </Button>
          <Select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            sx={{ ml: 2 }}
          >
            <MenuItem value="en-US">English</MenuItem>
            <MenuItem value="uk-UA">Ukrainian</MenuItem>
          </Select>
        </Box>
        <Typography variant="h6" gutterBottom>
          Or manually select a category:
        </Typography>
        <Select
          value={selectedCategoryId}
          onChange={(e) => setSelectedCategoryId(e.target.value)}
          fullWidth
          displayEmpty
          variant="outlined"
        >
          <MenuItem value="">
            <em>Select a category</em>
          </MenuItem>
          {categories.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
        <Box sx={{ my: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleManualSave}
            disabled={loading}
            startIcon={<Add />}
          >
            {loading ? 'Saving...' : 'Save Manually'}
          </Button>
        </Box>
        <Typography variant="h5" component="h2" gutterBottom>
          Manage Categories
        </Typography>
        <Grid container spacing={2} sx={{ backgroundColor: '#f0f0f0', padding: 2, borderRadius: 2 }}>
          {categories.map((category) => (
            <Grid item xs={12} key={category.id}>
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#d1e7dd', padding: 1, borderRadius: 1 }}>
                <Typography variant="h6" color="primary.dark">{category.name}</Typography>
                <Box>
                  <IconButton color="info" onClick={() => handleOpenEditDialog(category)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDeleteCategory(category.id)}>
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 4 }}>
          <TextField
            label="New Category Name"
            fullWidth
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddCategory}
            disabled={loading}
            startIcon={<Add />}
            sx={{ mt: 2 }}
          >
            Add Category
          </Button>
        </Box>
        <Dialog open={!!editingCategory} onClose={() => setEditingCategory(null)}>
          <DialogTitle>Edit Category</DialogTitle>
          <DialogContent>
            <TextField
              label="Category Name"
              fullWidth
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              variant="outlined"
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditingCategory(null)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleUpdateCategory} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
          User Messages
        </Typography>
        <Grid container spacing={2}>
          {categories.map((category) => (
            <Grid item xs={12} key={category.id}>
              <Typography variant="h6" gutterBottom sx={{ backgroundColor: '#ffebcd', padding: 1, borderRadius: 1 }}>
                {category.name}
              </Typography>
              {messages
                .filter((message) => message.categoryId === category.id)
                .map((message) => (
                  <Box display="flex" justifyContent="space-between" alignItems="center" key={message.id} sx={{ backgroundColor: '#f8f9fa', padding: 1, marginBottom: 1, borderRadius: 1 }}>
                    <Typography>{message.content}</Typography>
                    <Box>
                      <IconButton color="info" onClick={() => handleEditMessage(message)}>
                        <Edit />
                      </IconButton>
                      <IconButton color="error" onClick={() => handleDeleteMessage(message.id)}>
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
            </Grid>
          ))}
        </Grid>

        <Dialog open={!!editingMessage} onClose={() => setEditingMessage(null)}>
          <DialogTitle>Edit Message</DialogTitle>
          <DialogContent>
            <TextField
              label="Message Content"
              fullWidth
              value={newMessageContent}
              onChange={(e) => setNewMessageContent(e.target.value)}
              variant="outlined"
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditingMessage(null)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleUpdateMessage} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
}

export default App;
